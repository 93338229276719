@import "../settings/colors";
@import "../settings/typography";

button {
  text-align: center;
  outline: 0;
  border: 0;
  cursor: pointer;
  border-radius: 3px;
  color: white;
  background-color: #9e9e9e;
  margin: 0 10px;

  &.primary {
    background-color: $color-brand;
    color: $color-white;
  }

  &,
  &.lg {
    padding: 12px 40px;
    font-size: 24px;
    line-height: 29px;
    font-weight: $font-weight-bold;
  }

  &.md {
    padding: 8px 16px;
    font-size: 16px;
    line-height: 20px;
    font-weight: $font-weight-regular;
  }

  &.secondary {
    background-color: $color-white;
    color: $color-brand;
    padding: 8px 62px;
    font-size: 16px;
    line-height: 19px;
    font-weight: $font-weight-bold;
  }

  &.ghost {
    padding: 12px 40px;
    font-size: 24px;
    font-weight: $font-weight-bold;
    color: $color-brand;
    background-color: $color-white;
    line-height: 29px;
    border: 1px solid $color-brand;

  }

  &[disabled],
  &.disabled {
    background-color: $color-gray-8;
    color: $color-white;
    pointer-events: none;
  }
}
