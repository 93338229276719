@import "../settings/typography";
@import "../settings/colors";

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

body {
  font-family: $font-family;
  font-weight: $font-weight-regular;
  background-color: $color-white;
}
