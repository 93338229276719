h2 {
  font-size: 48px;
}

h3 {
  font-size: 40px;
}

h4 {
  font-size: 32px;
}

h5 {
  font-size: 28px;
}

h6 {
  font-size: 24px;
}
