@import "../settings/colors";

.text-color-white {
  color: $color-white;
}

.text-color-black {
  color: $color-black;
}

.text-color-brand {
  color: $color-brand;
}

.text-color-dark {
  color: $color-dark;
}

.text-center {
  text-align: center;
}
