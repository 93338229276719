body {
  color: $color-black;
}

.login-container {
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;

  &:before {
    content: '';
    background: url("../../assets/img/background/Background.png");
    background-size: cover;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    opacity: .6;
    position: absolute;
    z-index: -1;
  }


  input[type=text], input[type=password] {
    width: 300px;
  }


  .cc {
    color: #4D4D4D;
    font-size: 16px;
  }

  .login-box {
    @include media-max-width(425px) {
      zoom: 60%;
    }
    @include media-max-width(1200px) {
      zoom: 80%;
    }
    background: #FFFFFF;
    padding: 26px 40px 40px;
    display: flex;
    flex-flow: column;
    width: 387px;
    min-height: 623px;
    border-radius: 10px;
    align-items: center;
    position: relative;
    box-shadow: 0 5px 20px 0 #6d6e6f14;

    #back-btn {
      display: none;
    }

    button {
      width: 126px;
      height: 29px;
      line-height: 16px;
    }

    &__title {
      font-size: 18px;
      text-transform: uppercase;
      display: flex;
      flex-flow: row;
      justify-content: center;
      align-items: center;

      .cc-logo {
        width: 130px;
        margin-right: 8px;
      }
    }

    &__welcome-msg {
      margin-top: 25px;
      display: flex;
      flex-flow: column;
      align-items: center;

      h1 {
        font-size: 42px;
        font-weight: normal;
      }

      p {
        font-size: 14px;
        color: $color-black;
      }
    }

    &__form {
      margin-top: 45px;
      font-size: 14px;
      min-width: 251px;
      height: 100%;
      display: flex;
      flex-flow: column;
      align-items: center;

      input {
        margin-top: 5px;
      }

      div {
        margin-bottom: 15px;
      }

      .form__database {
        border-top: 1px solid #EDEAE9;
        padding-top: 15px;
        margin-top: 15px;
        margin-bottom: 37px;
        width: 100%;
        display: flex;
        flex-flow: row wrap;
        justify-content: flex-start;

        p {
          width: 100%;
          font-size: 14px;
          color: $color-black;

          .tooltip {
            position: relative;
            font-size: 14px;

            &:hover {
              .tooltip-text {
                visibility: visible;
              }
            }

            .tooltip-text {
              font-family: $font-family;
              font-weight: $font-weight-regular;
              font-style: normal;

              visibility: hidden;
              width: 350px;
              background-color: $color-dark;
              color: #FFF;
              padding: 10px;
              border-radius: 5px;
              position: absolute;
              bottom: 22px;
              left: 0;
              transform: translate(-167.5px, 0);
              z-index: 100;

              &::after {
                content: " ";
                position: absolute;
                top: 100%;
                left: 50%;
                margin-left: -5px;
                border-width: 5px;
                border-style: solid;
                border-color: black transparent transparent transparent;
              }
            }
          }
        }

        &__input-wrapper {
          display: flex;
          align-items: flex-start;
          flex-direction: column;
          gap: 19px;
          margin-top: 15px;
          margin-left: 15px;

          .form__database__type {
            display: flex;
            height: 31px;
            width: 100%;
            align-items: center;
            justify-content: center;
            gap: 12px;
            border-radius: 5px;
            padding: 8px;
            font-family: 'Segoe UI', sans-serif;
          }

          .from__database__selected-text {
            display: none;
            color: #000000;
          }

          input:checked + .form__database__type {
            background: #F1F2F3;

            .from__database__selected-text {
              display: inline-block;
              font-size: 10px;
              font-style: italic;
              color: #000000;
            }
          }

          label {
            font-size: 16px;
            text-transform: uppercase;
            display: flex;
            align-items: center;
            height: 100%;
            color: #000000;
            cursor: pointer;
          }

          input {
            width: 16px;
            height: 16px;
            accent-color: $color-brand;
            margin-top: 0;
            margin-right: 6px;
          }
        }
      }

      button {
        width: 172px;
        height: 29px;
        border-radius: 5px;
      }

      .password {
        position: relative;
        margin-bottom: 0;

        &-show__wrapper {
          position: absolute;
          top: calc(50% + 0.5px);
          right: 12px;
          cursor: pointer;

          .cc {
            padding: 2px 2px 2px 0;
          }
        }
      }

      .remember {
        display: flex;
        align-items: center;

        input {
          width: 16px;
          height: 16px;
          accent-color: $color-brand;
          margin-top: 0;
          margin-right: 6px;
        }
      }

      .forgot {
        display: flex;
        justify-content: flex-end;
        width: 100%;

        &__button {
          background: transparent;
          color: $color-brand;
          font-size: 14px;
          line-height: 14px;
          padding: 5px 10px;
          margin: 0;
          width: fit-content;
          font-weight: 400;
        }
      }
    }

    .hidden {
      display: none !important;
    }
  }

  .restart-password {
    &__header {
      display: flex;
      justify-content: center;
      align-items: center;
      margin-bottom: 120px;
      width: 100% !important;
      padding: 0 !important;
      background: transparent;
      line-height: 21px !important;

      i {
        font-size: 21px;
      }

      &_text {
        font-size: 21px;
        font-weight: 600;
        color: #000000;
      }
    }

    &__form_info {
      display: flex;
      align-items: center;
      flex-flow: column wrap;
      margin-bottom: 67px;

      &__heading {
        font-size: 16px;
        text-align: center;
      }
    }

    &__form_field {
      margin-bottom: 200px;
    }

    &__button {
      background: $color-brand;
      font-size: 16px;
      width: 151px !important;
      padding: 15px 0;
      display: flex;
      justify-content: center;
      align-items: center;
      font-weight: 400;

      &--again {
        position: absolute;
        bottom: 40px;
        left: auto;
        right: auto;
      }
    }

  }
}

.invalid-field,
.invalid-field-credentials {
  color: #FF5E5E;
  font-size: 16px;
  padding-top: 5px;
  width: 100%;
  letter-spacing: 0;
  text-align: center;
  font-weight: 600;
}

.notification {
  display: flex;
  position: absolute;
  justify-content: center;
  right: 50%;
  border: 3px solid #33A069;
  background-color: #E6F3EC;
  border-radius: 10px;
  z-index: 10;
  top: 0;
  transition: transform 2s ease-in-out, opacity 2s ease-in-out;

  &__content {
    padding: 36px 80px;
    width: 440px;
    position: relative;
    display: flex;
    justify-content: center;
    flex-flow: row wrap;



    &__button {
      position: absolute;
      top: 34px;

      &__type {
        left: 34px;
      }

      &__close {
        right: 34px;
        cursor: pointer;
      }
    }

    &__text {
      line-height: 1;

      &__header {
        font-size: 22px;
        font-weight: bold;
        margin-bottom: 16px;
      }

      &__subtext {
        font-size: 16px;
        font-weight: normal;
      }
    }
  }

  &--hidden {
    opacity: 0;
    transform: translate(50%, -100%);
  }

  &--show {
    opacity: 1;
    transform: translate(50%, 25%);
  }
}

