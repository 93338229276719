footer {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  height: 20px;
  text-align: center;
  font-size: 14px;
  color: #4D4D4D;
  @include media-max-width(425px) {
    zoom: 60%;
  }
  @include media-max-width(1200px) {
    zoom: 80%;
  }
}
