@import "../settings/colors";
@import "../settings/typography";

textarea, input { outline: none; }

input[type=text],
input[type=password],
input[type=number] {
  background: #FFFFFF;
  border: 1px solid #CCCCCC;
  border-radius: 5px;
  height: 31px;
  -webkit-appearance: none;
  padding: 5px 10px;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

::placeholder {
  color: #000000;
  opacity: 0.5;
}


.radio {
  margin: 0.5rem;
  input[type="radio"] {
    position: absolute;
    opacity: 0;
    + .radio-label {
      &:before {
        content: '';
        background: #ffffff;
        border-radius: 100%;
        border: 2px solid #757575;
        display: inline-block;
        width: 1.3em;
        height: 1.3em;
        position: relative;
        top: -0.2em;
        margin-right: 1em;
        vertical-align: top;
        cursor: pointer;
        text-align: center;
        transition: all 250ms ease;
      }
    }
    &:checked {
      + .radio-label {
        &:before {
          background-color: $color-brand;
          box-shadow: inset 0 0 0 4px #ffffff;
        }
      }
    }
    &:focus {
      + .radio-label {
        &:before {
          outline: none;
          border-color: $color-brand;
        }
      }
    }
    &:disabled {
      + .radio-label {
        &:before {
          box-shadow: inset 0 0 0 4px $color-gray-12;
          border-color: darken($color-gray-12, 25%);
          background: darken($color-gray-12, 25%);
        }
      }
    }
    + .radio-label {
      &:empty {
        &:before {
          margin-right: 0;
        }
      }
    }
  }
}
