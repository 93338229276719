.container {
  width: 100%;
  max-width: 1760px;
  margin-left: auto;
  margin-right: auto;
  padding: 0 24px;
}

.full-width {
  width: 100%;
}
