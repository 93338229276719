$color-white: #ffffff;
$color-black: #000000;

$color-brand: #FF5500;

$color-orange: #F05C10;
$color-orange-light: #FFEDE5;
$color-orange-lighter: #FDEDE5;

$color-dark: #191919;

$color-gray-13: #444444;
$color-gray-12: #767676;
$color-gray-11: #7C7C7C;
$color-gray-10: #707070;
$color-gray-9: #A2A2A2;
$color-gray-8: #C7C7C7;
$color-gray-7: #E1E1E1;
$color-gray-6: #E2E2E2;
$color-gray-5: #E9E9E9;
$color-gray-4: #ECECEC;
$color-gray-3: #F0F0F0;
$color-gray-2: #F2F2F2;
$color-gray-1: #F5F5F5;

$color-blue: #1290D0;
$color-yellow: #FCC10B;
$color-green: #36A93A;
$color-pink: #E51852;
$color-red: #E10000;

$login-main-color: #9E9E9E;
