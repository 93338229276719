@import "../settings/typography";


.cc
{
  display: inline-block;
  font: normal normal normal 14px/1 $font-cc-icons-family;
  font-size: inherit;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: $login-main-color;
}

.cc-clipboard:before {
  content: "\e906";
}

.cc-angel-right:before {
  content: "\e904";
}
.cc-settings:before {
  content: "\f013";
}
.cc-drag-drop:before {
  content:"\f142";
}
.cc-reiheIcon:before {
  content: "\e931";
}
.cc-movie:before {
  content: "\f008";
}
.cc-extend:before {
  content: "\f039";
}

.cc-shorten:before {
  content: "\f2d1";
}

.cc-eye:before {
  content: "\f06e";
}

.cc-eye-blind:before {
  content: "\e915";
}

.cc-movie-circle:before {
  content: "\16e";
}
.cc-tag-circle:before {
  content: "\16c";
}

.cc-planner-logo:before {
  content: "\14a";
}

.cc-trash:before {
  content: "\f1f8";
}

.cc-home:before {
  content: "\e91a";
}

.cc-bullhorn:before {
  content: "\E90B";
}

.cc-times:before {
  content: "\f00d";
}

.cc-lock:before {
  content: "\f023";
}

.cc-unlock:before {
  content: "\f09c";
}

.cc-folder-plus:before {
  content: "\f07e";
}

.cc-folder-full:before {
  content: "\f07b";
}

.cc-search:before {
  content: "\f002";
}

.cc-folder-add:before {
  content: "\f07e";
}

.cc-fulscreen:before
{
  content:"\0210";
}

.cc-icon-online:before
{
  content: "\02A5";
}

.cc-topics-logo:before
{
  content: "\0030";
}

.cc-programmer-logo:before
{
  content: "\0031";
}

.cc-select-logo:before {
  content: "\013e";
}

.cc-news:before
{
  content: "\0027";
}

.cc-world:before
{
  content: "\0029";
}

.cc-plus:before {
  content: "\f067";
}

.cc-plus-circle:before
{
  content: "\0028";
}

.cc-plus-circle-full:before {
  content: "\f055";
}

.cc-minus-circle-full:before {
  content: "\f056";
}

.cc-source:before {
  content: "\0026";
}

.cc-series:before {
  content: "\03B6";
}

.cc-vod-logo:before
{
  content: "\0142";
}
.cc-content-logo:before {
  content: "\e907";
}
.cc-gema-logo:before
{
  content: "\e902";
}

.cc-forecast-logo:before
{
  content: "\e92d";
}

.cc-shield-logo:before
{
  content: "\0184";
}

.cc-assets-logo:before
{
  content: "\015a";
}

.cc-teamer-logo:before
{
  content: "\0145"
}

.cc-dashboard-logo:before
{
  content: "\0144"
}

.cc-movie-icon:before
{
  content: "\03B5";
}

.cc-series-icon:before
{
  content: "\03B6";
}

.cc-topics-trends:before
{
  content: "\e90e";
}

.cc-date-time:before
{
  content: "\e90d";
}

.cc-status-icon:before
{
  content: "\e914";
}

.cc-add-videos:before
{
  content: "\e919";
}

.cc-user:before
{
  content: "\f007"
}

.cc-instagram-comment:before
{
  content: "\e90f"
}

.cc-instagram-like:before
{
  content: "\e910"
}

.cc-instagram-share:before
{
  content: "\e911"
}

.cc-tasks:before
{
  content: "\e916";
}

.cc-bell:before {
  content: "\e928";
}

.cc-camera:before {
  content: "\f030";
}

.cc-calendar:before {
  content: "\e96f";
}

.cc-icon-radio:before {
  content: "\e905";
}

.cc-icon-desktop:before {
  content: "\f108"
}

.cc-icon-show:before
{
  content: "\f06e";
}

.cc-icon-hide:before
{
  content: "\e915";
}

.cc-icon-wizard:before
{
  content: "\e913";
}

.cc-a:before {
  content: "\44a";
}

.cc-b:before {
  content: "\44b";
}

.cc-c:before {
  content: "\44c";
}

.cc-d:before {
  content: "\44d";
}

.cc-e:before {
  content: "\44e";
}

.cc-f:before {
  content: "\44f";
}

.cc-g:before {
  content: "\450";
}

.cc-h:before {
  content: "\451";
}

.cc-i:before {
  content: "\452";
}

.cc-j:before {
  content: "\453";
}

.cc-k:before {
  content: "\454";
}

.cc-l:before {
  content: "\455";
}

.cc-m:before {
  content: "\456";
}

.cc-n:before {
  content: "\457";
}

.cc-o:before {
  content: "\458";
}

.cc-p:before {
  content: "\459";
}

.cc-q:before {
  content: "\45a";
}

.cc-r:before {
  content: "\45b";
}

.cc-s:before {
  content: "\45c";
}

.cc-t:before {
  content: "\45d";
}

.cc-u:before {
  content: "\45e";
}

.cc-v:before {
  content: "\45f";
}

.cc-w:before {
  content: "\460";
}

.cc-t:before {
  content: "\461";
}

.cc-u:before {
  content: "\462";
}

.cc-v:before {
  content: "\463";
}

.cc-reset:before {
  content: "\f021";
}

.cc-facebook:before {
  content: "\e91b";
}

.cc-folder-al:before {
  content: "\e91d";
}

.cc-folder-ar:before {
  content: "\e91e";
}

.cc-folder-ds:before {
  content: "\e920";
}

.cc-folder-fs:before {
  content: "\e921";
}

.cc-folder-lz:before {
  content: "\e922";
}

.cc-folder-ms:before {
  content: "\e923";
}

.cc-folder-ps:before {
  content: "\e924";
}

.cc-folder-pr:before {
  content: "\e925";
}

.cc-folder-pz:before {
  content: "\e926";
}

.cc-folder-se:before {
  content: "\e927";
}

.cc-folder-tr:before {
  content: "\e928";
}

.cc-folder-tf:before {
  content: "\e929";
}

.cc-folder-ve:before {
  content: "\e92a";
}

.cc-folder-wb:before {
  content: "\e92b";
}
.cc-folder-re:before {
  content: "\e933";
}
.cc-folder-wi:before {
  content: "\e92e";
}

.cc-search-plus:before {
  content: "\f00e";
}

.cc-icon-text-minus:before {
  content: "\00E5";
}

.cc-icon-text-plus:before {
  content: "\00E6";
}

.cc-icon-raster-minus:before {
  content: "\00E2";
}

.cc-icon-raster-plus:before {
  content: "\00E3";
}

.cc-fullscreen:before {
  content: "\0210";
}

.cc-logout:before {
  content: "\020e";
}

.cc-money:before {
  content: "\e901";
}

.cc-tag:before {
  content: "\e975";
}

.cc-tc:before {
  content: "\0118";
}

.cc-tc-deactivated:before {
  content: "\0119";
}

.cc-windows:before {
  content: "\e96e";
}

.cc-windows-plus:before {
  content: "\e900";
}

.cc-sound-0:before {
  content: "\039E";
}

.cc-sound-1:before {
  content: "\e9a6";
}

.cc-sound-2:before {
  content: "\e9a5";
}

.cc-sound-3:before {
  content: "\e9a7";
}

.cc-logo-adsales:before {
  content: "\e917";
}

.cc-logo-scheme:before {
  content: "\014b";
}

.cc-series:before {
  content: "\03B6";
}

.cc-reihe:before {
  content: "\e931";
}

.cc-magnetism-on:before {
  content: "\03B9";
}

.cc-magnetism-off:before {
  content: "\03BA";
}

.cc-pf-sd:before {
  content: "\018b";
}

.cc-pf-uhd:before {
  content: "\0192";
}

.cc-pf-hd:before {
  content: "\0190";
}

.cc-pf-3d:before {
  content: "\0190";
}

.cc-plus-icon:before {
  content: "\0028";
}

.cc-date:before {
  content: "\e91f";
}

.cc-pencil:before {
  content: "\f040";
}

.cc-copy:before {
  content: "\f0c5";
}

.cc-angle-down:before {
  content: "\f107";
}

.cc-angle-right:before {
  content: "\e904";
}

.cc-angle-left:before {
  content: "\f104";
}

.cc-angle-up:before {
  content: "\e903";
}

.cc-angle-double-right:before {
  content: "\f101";
}

.cc-angle-double-left:before {
  content: "\f100";
}

.cc-info-circle:before {
  content: "\f05a";
}

.cc-check:before {
  content: "\f00c";
}

.cc-check-circle:before {
  content: "\f058";
}

.cc-calculator:before {
  content: "\f1ec";
}

.cc-download:before {
  content: "\f019";
}

.cc-upload:before {
  content: "\f093";
}

.cc-file-excel:before {
  content: "\f1c3";
}

.cc-file-o:before {
  content: "\f016";
}

.cc-file:before {
  content: "\f15b";
}

.cc-star:before {
  content: "\f006";
}

.cc-star-full:before {
  content: "\f005";
}

.cc-right-arrow:before {
  content: "\F061";
}

.cc-question-circle:before {
  content: '\0289';
}

.cc-left-arrow{
  transform: rotate(180deg);
  &:before {
    content: "\F061";
  }
}

.cc-warning-circle:before {
  content: "\0288";
  border:2px solid red;
  background: red;
  color: white;
  border-radius: 50%;
}
