@mixin media-min-width($min) {
  @media screen and (min-width: $min) {
    @content;
  }
}

@mixin media-max-width($max) {
  @media screen and (max-width: $max) {
    @content;
  }
}

@mixin media-min-max-width($min, $max) {
  @media screen and (min-width: $min) and (max-width: $max) {
    @content;
  }
}
