.to-right {
  margin-left: auto;
}

.to-left {
  margin-right: auto;
}

.center {
  margin-left: auto;
  margin-right: auto;
}
