$font-family: 'DINPro';
$font-icons-family: 'CC Icons';
$font-cc-icons-family: 'CreateCtrlFont';

$font-weight-regular: normal;
$font-weight-medium: 500;
$font-weight-bold: bold;

@font-face {
  font-family: $font-family;
  font-weight: $font-weight-regular;
  font-style: normal;
  src: url('../../assets/fonts/DINPro.woff') format("woff"),
       url('../../assets/fonts/DINPro.woff2') format("woff2"),
       url('../../assets/fonts/DINPro.ttf') format("truetype");
}

@font-face {
  font-family: $font-family;
  font-weight: $font-weight-medium;
  font-style: normal;
  src: url('../../assets/fonts/DINPro-medium.woff') format("woff"),
       url('../../assets/fonts/DINPro-medium.woff2') format("woff2"),
       url('../../assets/fonts/DINPro-medium.ttf') format("truetype");
}

@font-face {
  font-family: $font-family;
  font-weight: $font-weight-bold;
  font-style: normal;
  src: url('../../assets/fonts/DINPro-Bold.woff') format("woff"),
       url('../../assets/fonts/DINPro-Bold.woff2') format("woff2"),
       url('../../assets/fonts/DINPro-Bold.ttf') format("truetype");
}

@font-face {
  font-family: $font-icons-family;
  font-weight: $font-weight-regular;
  font-style: normal;
  font-display: block;
  src: url('../../assets/fonts/cc-icons-font.woff') format("woff"),
        url('../../assets/fonts/cc-icons-font.ttf') format("truetype");
}

@font-face {
  font-family: $font-cc-icons-family;
  font-weight: $font-weight-regular;
  font-style: normal;
  font-display: block;
  src:    url('../../assets/fonts/createctrl/CreateCtrl-Webfont.ttf') format("truetype"),
          url('../../assets/fonts/createctrl/CreateCtrl-Webfont.eot') format("embedded-opentype"),
          url('../../assets/fonts/createctrl/CreateCtrl-Webfont.svg') format("svg");
}
